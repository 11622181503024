<template>
  <div>
    <a href="#" class="flix-html-a" @click.prevent="setActive">
      <flixIcon id="check" v-if="sms.status === 'active'"/>
      <flixIcon id="unchecked" v-else />
      Zusage ebenso als SMS versenden (SMS-Guthaben notwendig)
    </a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object
  },
  data () {
    return {
      sms: JSON.parse(JSON.stringify(this.data)),
      variables: this.$getUserVariables()
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setSave () {
      var s =
      {
        user: this.variables.user.md5_id,
        ID: this.sms.to,
        filter: {
          ID: this.sms.ID
        },
        data: [this.sms]
      }
      this.$flix_post({
        data: s,
        url: 'reminder/save',
        callback: function () { }
      })
    },
    setActive () {
      if (this.sms.status === 'active') {
        this.sms.status = 'inactive'
      } else {
        this.sms.status = 'active'
      }
      this.setSave()
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
